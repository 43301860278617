<template>
  <div v-if="Object.keys(datasource).length">
    <v-card>
      <v-list-item three-line>
        <v-list-item-content>
          <div class="overline mb-4">
            datasourceid: {{ datasource.datasourceid }}
          </div>
          <v-list-item-title class="headline mb-1">
            {{ showActualValue() }}
          </v-list-item-title>
          <v-list-item-subtitle class="mt-4">
            <strong>{{ datasource.name }}</strong>
          </v-list-item-subtitle>
          <v-list-item-subtitle class="mt-2">
            {{ $t("last_update") }}:<br />{{
              formatTheDate(sensorData.gpstime)
            }}
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-avatar size="100">
          <SensorIcon :sensortypeid="datasource.sensortypeid" />
        </v-list-item-avatar>
      </v-list-item>
      <v-card-actions>
        <span>
          {{ $t("raw_details") }}
        </span>
        <v-spacer></v-spacer>
        <v-btn icon @click="showRawDetails = !showRawDetails">
          <v-icon>{{
            showRawDetails ? "mdi-chevron-up" : "mdi-chevron-down"
          }}</v-icon>
        </v-btn>
      </v-card-actions>
      <v-expand-transition>
        <div v-show="showRawDetails">
          <v-divider></v-divider>
          <v-card-text>
            <pre style="font-size:.7rem; line-height:1.1rem;">{{
              datasource
            }}</pre>
          </v-card-text>
        </div>
      </v-expand-transition>
    </v-card>
  </div>
</template>

<script>
import SensorIcon from "@/components/SensorIcon";
import formatDate from "@/formatters/formatDate";
import "@/mixins/sensorMath";

export default {
  name: "Sensor",
  components: {
    SensorIcon
  },
  props: {
    datasourceId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      showRawDetails: false
    };
  },
  computed: {
    datasource() {
      return (
        (this.datasourceId &&
          this.$store.getters.datasource(this.datasourceId)) ||
        {}
      );
    },
    sensorData() {
      return (
        (this.datasourceId &&
          this.$store.getters.sensorData(this.datasourceId)) ||
        {}
      );
    }
  },
  methods: {
    formatTheDate(date) {
      return formatDate(date);
    },
    showActualValue() {
      if (!Object.keys(this.sensorData).length) {
        return "-";
      }
      const unit =
        this.datasource.units === "-" || !this.datasource.units
          ? ""
          : this.datasource.units;
      const value = this.divide(
        this.setMinMax(
          this.multiply(
            this.sensorData.value,
            this.datasource.a0,
            this.datasource.a1,
            this.datasource.a2,
            this.datasource.a3
          ),
          this.datasource.min,
          this.datasource.max
        ),
        this.datasource.divisor
      );
      return `${value} ${unit}`;
    }
  }
};
</script>
