<template>
  <div v-if="Object.keys(assetOBU).length">
    <h2 class="mb-4">OBU</h2>
    <v-row>
      <v-col>
        <v-simple-table dense>
          <tbody>
            <tr v-if="assetOBU.oburegistrationid && !deleted">
              <td><strong>ID:</strong></td>
              <td>{{ assetOBU.obuid }}</td>
              <td>
                <strong>{{ $t("obu.status") }}:</strong>
              </td>
              <td>{{ $t(`obu.${assetOBU.obustatus}`) }}</td>
            </tr>
            <tr v-if="assetOBU.oburegistrationid && !deleted">
              <td>
                <strong>{{ $t("obu.regDate") }}:</strong>
              </td>
              <td v-if="assetOBU.oburesgistrationdate">
                {{ formatTheDate(assetOBU.oburesgistrationdate) }}
              </td>
              <td v-else>-</td>
              <td>
                <strong>{{ $t("obu.actDate") }}:</strong>
              </td>
              <td v-if="assetOBU.obuactivationdate">
                {{ formatTheDate(assetOBU.obuactivationdate) }}
              </td>
              <td v-else>-</td>
            </tr>
            <tr>
              <td>
                <strong>{{ $t("obu.regID") }}:</strong>
              </td>
              <td v-if="!deleted">
                {{ assetOBU.oburegistrationid || $t("obu.notRegistered") }}
              </td>
              <td v-else>{{ $t("obu.notRegistered") }}</td>
              <template v-if="assetOBU.oburegistrationid || assetOBU.imei">
                <td>
                  <strong>{{ $t("obu.allocateSIM") }}:</strong>
                </td>
                <td>
                  <div>
                    <v-switch
                      color="primary"
                      value
                      :input-value="assetOBU.oburegistrationid"
                      @change="allocateSIM"
                      :error="error"
                      :success="success"
                      :loading="loading"
                      class="mr-3"
                    ></v-switch>
                    <template v-if="error"
                      ><span color="red">{{ $t("obu.error") }}</span></template
                    >
                    <template v-if="success"
                      ><span color="green">{{
                        $t("obu.success")
                      }}</span></template
                    >
                  </div>
                </td>
              </template>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
  </div>
  <h2 v-else class="mb-4">{{ $t("obu.noOBUInfo") }}</h2>
</template>

<script>
import formatDate from "@/formatters/formatDate";
const { callBffAPI } = require("ngt-frontend-core").apiOpsBff;

export default {
  name: "OBUState",
  props: {
    assetOBU: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      error: false,
      success: false,
      loading: false,
      deleted: false
    };
  },
  methods: {
    formatTheDate(date) {
      return formatDate(date);
    },
    allocateSIM(value) {
      if (value && this.assetOBU?.imei && !this.assetOBU?.oburegistrationid) {
        this.error = false;
        this.success = false;
        this.loading = true;
        callBffAPI({
          url: "/registrations",
          method: "POST",
          data: { imei: this.assetOBU.imei }
        })
          .then(() => {
            this.success = true;
            this.deleted = false;
          })
          .catch(() => {
            this.error = true;
          })
          .finally(() => {
            this.loading = false;
            this.$store.dispatch("resetAssetDetails");
          });
      } else if (!value && this.assetOBU?.oburegistrationid) {
        this.error = false;
        this.success = false;
        this.loading = true;
        callBffAPI({
          url: `/registrations/${this.assetOBU.oburegistrationid}`,
          method: "DELETE"
        })
          .then(() => {
            this.success = true;
            this.deleted = true;
          })
          .catch(() => {
            this.error = true;
            this.deleted = false;
          })
          .finally(() => {
            this.loading = false;
            this.$store.dispatch("resetAssetDetails");
          });
      }
    }
  }
};
</script>
