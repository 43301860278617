<script>
import { Bar } from "vue-chartjs";

export default {
  extends: Bar,
  props: {
    labels: {
      type: Array,
      default: () => []
    },
    datasets: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    options: {
      animation: {
        duration: 0 // general animation time
      },
      hover: {
        animationDuration: 0 // duration of animations when hovering an item
      },
      elements: {
        point: {
          radius: 1,
          borderColor: "white"
        },
        rectangle: {
          borderWidth: 0
        }
      },
      showLines: false,
      responsiveAnimationDuration: 0, // animation duration after a resize
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        yAxes: [
          {
            ticks: {
              // stepSize: 50,
              // maxTicksLimit: 3
              // min: -10,
              // max: 10,
            }
          }
        ]
      }
    },
    options2: {
      animation: {
        duration: 10 // general animation time
      },
      elements: {
        point: {
          radius: 0,
          borderColor: "white"
        },
        rectangle: {
          borderWidth: 0
        }
      },
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        yAxes: [
          {
            ticks: {
              // stepSize: 50,
              // maxTicksLimit: 3
              // min: -10,
              // max: 10,
            }
          }
        ],
        xAxes: [
          {
            // typex: 'time',
            ticks: {
              autoSkip: false,
              maxRotation: 0,
              minRotation: 0,
              callback: function(value, index, values) {
                if (value.endsWith(" ")) return "";
                return value;
              }
            }
            // time:
            // {
            //   displayFormats:
            //   {
            //     'millisecond': 'HH:mm:ss',
            //     'second': 'HH:mm:ss',
            //     'minute': 'HH:mm:ss',
            //     'hour': 'HH:mm:ss',
            //     'day': 'HH:mm:ss',
            //     'week': 'HH:mm:ss',
            //     'month': 'HH:mm:ss',
            //     'quarter': 'HH:mm:ss',
            //     'year': 'HH:mm:ss'
            //   }
            // },
            // gridLines:
            // {
            //   display: false
            // }
          }
        ]
      }
    }
  }),
  mounted() {
    // console.log("mounted");
  },
  methods: {
    render() {
      // console.log("render");
      this.renderChart(
        { labels: this.labels, datasets: this.datasets },
        this.options2
      );
    },
    update() {
      // console.log("update");
      let chart = this.$data._chart;
      chart.update();
    },
    setRange(range) {
      if (range[0] == null) {
        delete this.options2.scales.yAxes[0].ticks.min;
        delete this.options2.scales.yAxes[0].ticks.max;
      } else {
        this.options2.scales.yAxes[0].ticks.min = range[0];
        this.options2.scales.yAxes[0].ticks.max = range[1];
      }
    }
  }
};
</script>
