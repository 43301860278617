import Vue from "vue";

Vue.mixin({
  methods: {
    multiply(value, a0, a1, a2, a3) {
      if (!a0 && !a1 && !a2 && !a3) {
        return value;
      }
      return (
        a0 + value * a1 + Math.pow(value, 2) * a2 + Math.pow(value, 3) * a3
      );
    },
    setMinMax(value, min, max) {
      return Math.max(min, Math.min(value, max));
    },
    divide(value, divisor) {
      if (!divisor) {
        return value;
      }
      return value / divisor;
    }
  }
});
