<template>
  <v-card v-if="markerLatLng.length && showMap" style="height: 100%">
    <l-map style="height: 100%" :zoom="zoom" :center="markerLatLng">
      <l-tile-layer :url="url"></l-tile-layer>
      <l-circle-marker
        :lat-lng="markerLatLng"
        :radius="radius"
        :color="color"
      ></l-circle-marker>
    </l-map>
  </v-card>
</template>

<script>
import { LMap, LTileLayer, LCircleMarker } from "vue2-leaflet";

export default {
  name: "Map",
  components: {
    LMap,
    LTileLayer,
    LCircleMarker
  },
  props: {
    markerLatLng: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      apiKey: "AIzaSyDHkoCeO7ja_LqfrQzshWA3oWAJPyZWsWw",
      zoom: 15,
      radius: 8,
      color: "red",
      url: "http://{s}.tile.osm.org/{z}/{x}/{y}.png",
      showMap: false
    };
  },
  mounted() {
    this.showMap = true;
    this.centerUpdated(this.markerLatLng);
  },
  // computed: {
  //     url() {
  //         return `https://maps.googleapis.com/maps/api/staticmap?center=${this.markerLatLng[0]},${this.markerLatLng[1]}&zoom=${this.zoom}&size=400x400&key=${this.apiKey}`
  //     }
  // },
  methods: {
    zoomUpdated(zoom) {
      this.zoom = zoom;
    },
    centerUpdated(center) {
      this.center = center;
    },
    boundsUpdated(bounds) {
      this.bounds = bounds;
    }
  }
};
</script>

<style>
@import "~leaflet/dist/leaflet.css";
html,
body {
  height: 100%;
  margin: 0;
}
.leaflet-pane {
  z-index: 1;
}
.leaflet-top,
.leaflet-bottom {
  z-index: 1;
}
</style>
