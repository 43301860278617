<template>
  <div v-if="Object.keys(assetState).length">
    <h2 class="mb-4">
      {{ $t("message_id") }}: {{ assetState.messageid }} ({{
        formatTheDate(assetState.timestamp)
      }})
    </h2>
    <v-row>
      <v-col>
        <v-simple-table dense>
          <tbody>
            <tr>
              <td><strong>gpstime</strong></td>
              <td>{{ formatTheDate(assetState.gpstime) }}</td>
            </tr>
            <tr>
              <td><strong>vectorangle</strong></td>
              <td>{{ assetState.vectorangle }}</td>
            </tr>
            <tr>
              <td><strong>vectorspeed</strong></td>
              <td>{{ assetState.vectorspeed }}</td>
            </tr>
            <tr>
              <td><strong>altitude</strong></td>
              <td>{{ assetState.altitude }}</td>
            </tr>
            <tr>
              <td><strong>x</strong></td>
              <td>{{ assetState.x }}</td>
            </tr>
            <tr>
              <td><strong>y</strong></td>
              <td>{{ assetState.y }}</td>
            </tr>
            <tr>
              <td><strong>visiblesatelites</strong></td>
              <td>{{ assetState.visiblesatelites }}</td>
            </tr>
            <tr>
              <td><strong>valid</strong></td>
              <td>{{ assetState.valid }}</td>
            </tr>
            <tr>
              <td><strong>canal</strong></td>
              <td>{{ assetState.canal }}</td>
            </tr>
            <tr>
              <td><strong>reasoncode</strong></td>
              <td>{{ assetState.reasoncode }}</td>
            </tr>
            <tr>
              <td><strong>reasondata</strong></td>
              <td>{{ assetState.reasondata }}</td>
            </tr>
            <tr>
              <td><strong>age</strong></td>
              <td>{{ assetState.age }}</td>
            </tr>
            <tr>
              <td><strong>hash</strong></td>
              <td>{{ assetState.hash }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
  </div>
  <h2 v-else class="mb-4">{{ $t("waiting") }}</h2>
</template>

<script>
import formatDate from "@/formatters/formatDate";

export default {
  name: "AssetState",
  props: {
    assetState: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  methods: {
    formatTheDate(date) {
      return formatDate(date);
    }
  }
};
</script>
