<template>
  <v-icon x-large>mdi {{ icon }}</v-icon>
</template>

<script>
export default {
  name: "SensorIcon",
  props: {
    sensortypeid: {
      type: Number,
      default: 0
    }
  },
  computed: {
    icon() {
      switch (this.sensortypeid) {
        case 2:
          return "mdi-directions-fork";
        case 3:
          return "mdi-car-key";
        case 4:
          return "mdi-car-electric";
        case 5:
          return "mdi-car-battery";
        case 19:
          return "mdi-car-door";
        case 15:
        case 16:
        case 17:
          return "mdi-car-coolant-level";
        case 18:
          return "mdi-car-parking-lights";
        case 11:
        case 23:
          return "mdi-car-cruise-control";
        case 10:
        case 14:
          return "mdi-counter";
        case 27:
          return "mdi-car-connected";
        case 26:
          return "mdi-car-emergency";
        case 6:
          return "mdi-thermometer";
        case 9:
          return "mdi-sine-wave";
        case 20:
          return "mdi-water-percent";
        case 21:
          return "mdi-alarm-light";
        case 34:
          return "mdi-pulse";
        case 22:
          return "mdi-account-hard-hat";
        case 13:
          return "mdi-domain";
        case 25:
          return "mdi-card-account-details";
        case 12:
          return "mdi-alert-octagon";
        case 7:
          return "mdi-clock";
        default:
          return "mdi-leak";
      }
    }
  }
};
</script>
