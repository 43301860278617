<template>
  <v-card class="component-container">
    <v-sheet class="d-flex flex-row ma-4 pa-4" dense outlined>
      <v-sheet style="width: 10%" class="mx-2 py-0 my-0" dense>
        <p class="font-weight-bold">Type:</p>
        <v-btn-toggle v-model="chartType" dense>
          <v-btn icon>
            <v-icon>mdi-chart-line</v-icon>
          </v-btn>
          <v-btn icon>
            <v-icon>mdi-chart-bar</v-icon>
          </v-btn>
        </v-btn-toggle>
        <!-- <v-checkbox v-model="chartType" label="Bars"></v-checkbox> -->
      </v-sheet>
      <v-sheet style="width: 50%" class="mx-2 py-0 my-0" dense>
        <p class="font-weight-bold">Sensors:</p>
        <v-select
          class="pt-0"
          v-model="selectedChannels"
          multiple
          :items="availableChannels"
          item-text="meta.label"
          item.value="meta.label"
          hide-details
        >
        </v-select>
      </v-sheet>
      <v-sheet style="width: 20%" class="mx-2 py-0 my-0" dense>
        <p class="font-weight-bold">Scale:</p>
        <v-select
          class="pt-0"
          v-model="yTicksRange"
          :items="yTicksRangeOptions"
          item-text="id"
          item-value="id"
          return-object
          hide-details
        >
        </v-select>
      </v-sheet>
      <v-sheet style="width: 20%" class="mx-2 py-0 my-0" dense>
        <p class="font-weight-bold">Data Length:</p>
        <v-select
          class="pt-0"
          v-model="maxDataLength"
          :items="enums.maxDataLengths"
          hide-details
        >
        </v-select>
      </v-sheet>
    </v-sheet>

    <ChannelChartLine
      v-if="!chartType"
      :height="height"
      :labels="labels"
      :datasets="datasets"
      ref="chartLine"
    />
    <ChannelChartBar
      v-if="chartType"
      :height="height"
      :labels="labels"
      :datasets="datasets"
      ref="chartBar"
    />
  </v-card>
</template>

<script>
import ChannelChartBar from "@/components/ChannelChartBar";
import ChannelChartLine from "@/components/ChannelChartLine";
import dayjs from "dayjs";
import enums from "@/app-data/data-graph-enums.json";

export default {
  name: "DataGraph",
  components: {
    ChannelChartBar,
    ChannelChartLine
  },
  props: {
    height: {
      type: Number,
      default: 100
    }
  },
  data() {
    return {
      chartType: 0,
      selectedChannels: [],
      yTicksRange: enums.yTicksRangeOptions[0],
      yTicksRangeOptions: enums.yTicksRangeOptions,
      labels: null,
      datasets: null,
      maxLabelCount: 10,
      maxDataLength: 30,
      currDataLength: 0,
      currGraphData: [],
      currLabels: [],
      channels: null
    };
  },
  computed: {
    datasourceIds() {
      return this.$store.getters.datasourceIds();
    },
    graphData() {
      return this.$store.state.selectedAsset.graphData;
    },
    availableChannels() {
      if (this.channels == null) return [];
      return this.channels;
    },
    enums() {
      return enums;
    }
    // visibleChannels() {
    //   return this.channels.filter(p =>
    //     this.selectedChannels.includes(p.meta.label));
    // }
  },
  watch: {
    datasourceIds() {
      this.channels = null;
      this.refresh();
    },
    graphData() {
      this.refresh();
    },
    chartType() {
      this.saveSettings();
      setTimeout(() => {
        this.renderChart();
      }, 0);
    },
    selectedChannels() {
      this.saveSettings();

      this.createDatasets();
      setTimeout(() => {
        this.renderChart();
      }, 0);
    },
    yTicksRange() {
      this.saveSettings();
      setTimeout(() => {
        this.renderChart();
      }, 0);
    },
    maxDataLength() {
      this.saveSettings();
      this.refresh();
    }
  },
  created: async function() {
    this.loadSettings();
  },
  mounted() {
    this.refresh();
  },
  methods: {
    channelsChanged() {
      this.createLabels();
      this.createDatasets();
      setTimeout(() => {
        this.renderChart();
      }, 0);
    },
    createLabels() {
      // console.log("createLabels");
      // const labels = [];
      // const length = this.channels[0].data.length;
      // for (let i = 0; i < length; i++) {
      //   labels.push("");
      // }
      // this.labels = labels;

      this.labels = this.currLabels;
    },
    createDatasets() {
      // console.log("createDatasets");
      const datasets = [];
      if (this.channels != null) {
        for (let i = 0; i < this.channels.length; i++) {
          const channel = this.channels[i];
          if (!this.selectedChannels.includes(channel.meta.label)) continue;
          const dataset = {};
          dataset.label = channel.meta.label;
          dataset.borderColor = channel.meta.color;
          dataset.backgroundColor = channel.meta.color;
          dataset.pointBackgroundColor = channel.meta.color;
          dataset.pointBorderColor = "white";
          dataset.borderWidth = 1;
          dataset.data = channel.data;
          datasets.push(dataset);
        }
      }

      this.datasets = datasets;
      // console.log("channels: ", this.channels, "datasets: ", this.datasets)
    },
    updateDatasets() {
      // console.log("updateDatasets");
      for (const dataset of this.datasets) {
        const channel = this.channels.find(p => p.meta.label === dataset.label);
        dataset.data = channel.data;
      }
      // console.log(this.labels)

      const times = [0, 20, 40, 60, 80];
      for (const time of times) {
        let dateStr = dayjs()
          .subtract(time, "second")
          .format("HH:mm:ss");
        this.labels[this.labels.length - 1 - time] = dateStr;
      }

      // this.updateChart();
      this.renderChart();
    },
    renderChart() {
      // console.log("renderChart");
      let chart = this.getChart();
      chart.setRange(this.yTicksRange.range);
      chart.render();
    },
    updateChart() {
      // console.log("updateChart");
      let chart = this.getChart();
      chart.update();
    },
    getChart() {
      let chartRef = this.chartType ? "chartBar" : "chartLine";
      if (!this.$refs[chartRef]) {
        console.log("chartRef is not defined!");
        return null;
      }
      return this.$refs[chartRef];
    },
    refresh() {
      // console.log("refresh")

      this.currDataLength =
        this.graphData.length < this.maxDataLength
          ? this.graphData.length
          : this.maxDataLength;
      this.currGraphData =
        this.graphData.length < this.maxDataLength
          ? this.graphData
          : this.graphData.slice(-this.maxDataLength);

      if (this.channels == null) {
        this.createChannels();
      }

      this.updateChannels();
    },
    createChannels() {
      // console.log("createChannels")

      const channels = [];
      for (let [i, datasourceId] of this.datasourceIds.entries()) {
        const datasource = this.$store.getters.datasource(datasourceId);
        // console.log(datasourceId, i)

        const color =
          enums.graphColors.length >= i ? enums.graphColors[i] : "black";

        const meta = {
          datasourceId: datasource.datasourceid,
          datasource: datasource,
          label: datasource.name,
          color
        };

        // console.log(this.currGraphData, this.currGraphData.length)

        const data = this.currGraphData.map(p =>
          this.adjustValue(
            p.messages.find(m => m.datasourceId === datasourceId).value,
            datasource
          )
        );
        const channel = { meta, data };

        channels.push(channel);
      }

      this.channels = channels;
      this.currLabels = this.currGraphData.map((p, i, arr) =>
        this.formatDate(p.date, i, arr.length)
      );
      this.channelsChanged();
      // console.log(this.channels)
    },
    updateChannels() {
      // console.log("updateChannels")

      // for (let channel of this.channels) {
      //   let value = 0;
      //   const sensorData = this.$store.getters.sensorData(
      //     channel.meta.datasourceId
      //   );
      //   // console.log(sensorData.gpstime, sensorData.value)

      //   if (sensorData) {
      //     value = this.adjustValue(sensorData.value, channel.meta.datasource);
      //   } else {
      //     console.log(
      //       `Error sensorData is invalid: ${sensorData}, datasourceId: ${channel.meta.datasourceId}`
      //     );
      //   }

      //   channel.data.shift();
      //   channel.data.push(value);
      // }

      const channels = [];
      for (let channel of this.channels) {
        const datasourceId = channel.meta.datasourceId;
        const datasource = this.$store.getters.datasource(datasourceId);
        const data = this.currGraphData.map(p =>
          this.adjustValue(
            p.messages.find(m => m.datasourceId === datasourceId).value,
            datasource
          )
        );
        channels.push({
          meta: channel.meta,
          data
        });
      }

      this.channels = channels;
      this.currLabels = this.currGraphData.map((p, i, arr) =>
        this.formatDate(p.date, i, arr.length)
      );
      this.channelsChanged();
      // console.log(this.channels)
    },
    adjustValue(value, datasource) {
      const result = this.divide(
        this.setMinMax(
          this.multiply(
            value,
            datasource.a0,
            datasource.a1,
            datasource.a2,
            datasource.a3
          ),
          datasource.min,
          datasource.max
        ),
        datasource.divisor
      );
      return result.toFixed(2);
    },
    formatDate(date, i, length) {
      const d = Math.ceil(length / this.maxLabelCount);
      const show = i % d === d - 1;

      // console.log(show, i, d)

      let dateStr = dayjs(date).format("HH:mm:ss");

      if (!show) {
        dateStr = dateStr + " ";
      }

      return dateStr;
    },
    saveSettings() {
      const settings = {
        chartType: this.chartType,
        selectedChannels: this.selectedChannels,
        yTicksRange: this.yTicksRange,
        maxDataLength: this.maxDataLength
      };
      this.$store.commit("setGraphChannels", settings);
    },
    loadSettings() {
      let settings = this.$store.state.graphChannels;

      if (settings == null) {
        settings = {
          chartType: 0,
          selectedChannels: [],
          yTicksRange: enums.yTicksRangeOptions[0],
          maxDataLength: 30
        };
      }

      this.chartType = settings.chartType;
      this.selectedChannels = settings.selectedChannels;
      this.yTicksRange = settings.yTicksRange;
      this.maxDataLength = settings.maxDataLength;
    }
  }
};
</script>

<style scoped>
.component-container {
  /* margin: 5px 0px 0px 0px;
  padding: 10px; */
  border-color: transparent;
  border-style: solid;
  border-width: thin;
  height: auto;
  width: "100%";
}
</style>
