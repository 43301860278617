<template>
  <v-container class="pa-4">
    <div v-if="selectedAsset">
      <v-container>
        <v-row>
          <v-col lg="9">
            <CurrentlySelectedAsset />
          </v-col>
          <v-col lg="3" align-self="center">
            <StreamDateSelector />
          </v-col>
        </v-row>
      </v-container>
      <v-divider class="my-6"></v-divider>
      <v-container>
        <v-row>
          <v-col>
            <OBUState :assetOBU="assetOBU" />
          </v-col>
        </v-row>
      </v-container>
      <v-divider class="my-6"></v-divider>
      <v-container>
        <v-row>
          <v-col>
            <AssetState :assetState="lastAssetState" />
          </v-col>
          <v-col>
            <Map
              v-if="Object.keys(lastAssetState).length"
              :markerLatLng="[lastAssetState.y, lastAssetState.x]"
            />
          </v-col>
        </v-row>
      </v-container>
      <v-divider class="my-6"></v-divider>
      <h2 class="mb-4">{{ $t("installed_sensors") }}</h2>
      <v-container>
        <v-row>
          <v-col
            v-for="datasourceId in datasourceIds"
            :key="datasourceId"
            cols="4"
            class="sensor-items"
          >
            <Sensor class="mb-4 mr-4" :datasourceId="datasourceId" />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <GsDataGraph :height="400" />
          </v-col>
        </v-row>
      </v-container>
    </div>
    <SelectAssetFirst v-else />
  </v-container>
</template>

<script>
import SelectAssetFirst from "@/components/SelectAssetFirst";
import CurrentlySelectedAsset from "@/components/CurrentySelectedAsset";
import Sensor from "@/components/Sensor";
import AssetState from "@/components/AssetState";
import StreamDateSelector from "@/components/StreamDateSelector";
import GsDataGraph from "@/components/DataGraph";
import Map from "@/components/Map";
import OBUState from "@/components/OBUState";

export default {
  name: "AssetDetails",
  components: {
    SelectAssetFirst,
    CurrentlySelectedAsset,
    Sensor,
    AssetState,
    StreamDateSelector,
    GsDataGraph,
    Map,
    OBUState
  },
  computed: {
    selectedAsset() {
      return this.$store.state.selectedAsset.objectId;
    },
    datasourceIds() {
      return this.$store.getters.datasourceIds();
    },
    lastAssetState() {
      return this.$store.state.selectedAsset.lastAssetState;
    },
    assetOBU() {
      // if( !this.$store.state.selectedAsset.assetDetails.obuid ) {
      //   return {};
      // }
      return {
        obuid: this.$store.state.selectedAsset.assetDetails.obuid || "",
        obustatus: this.$store.state.selectedAsset.assetDetails.obustatus || "",
        oburesgistrationdate:
          this.$store.state.selectedAsset.assetDetails.oburesgistrationdate ||
          "",
        obuactivationdate:
          this.$store.state.selectedAsset.assetDetails.obuactivationdate || "",
        oburegistrationid:
          this.$store.state.selectedAsset.assetDetails.oburegistrationid || "",
        imei: this.$store.state.selectedAsset.assetDetails.imei || ""
      };
    }
  }
};
</script>

<style scoped>
.sensor-items > :last-child {
  margin-right: 0 !important;
}
</style>
